import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// Add your image imports
import player1 from '../../images/player1.png';
import player2 from '../../images/player2.png';
import player3 from '../../images/player3.png';
import bananaImg from '../../images/banana.png';
import obstacleImg from '../../images/spikes_0.png';
import backgroundImg from '../../images/jungle_bg.png';
import starImg from '../../images/star.png';
import multiplierImg from '../../images/multiplier.png';
// Add your sound imports
import jumpSound from '../../images/jump.mp3';
import collectSound from '../../images/collect.mp3';
import crashSound from '../../images/crash.mp3';
import invisibleSound from '../../images/invisible.mp3';
import backgroundMusic from '../../images/music.mp3';

const GameCanvas = styled.canvas`
  border: 2px solid #333;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: relative;
`;

const GameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #1a1a1a;
  border-radius: 15px;
  margin: 50px auto 20px;
  position: relative;
  max-width: 1500px;
`;

const ScoreText = styled.div`
  color: #ffd700;
  font-size: 24px;
  font-family: "AngryMonkeySimple";
  margin: 10px 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const GameOverScreen = styled.div`
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  padding: 30px 50px;
  border-radius: 10px;
  text-align: center;
  color: white;
  font-family: 'AngryMonkeySimple';
  z-index: 10;

  h2 {
    font-size: 40px;
    margin: 0 0 20px 0;
    color: #fff;
  }

  p {
    font-size: 24px;
    margin: 10px 0;
    color: #fff;
  }
`;

const Button = styled.button`
  background: #ffd700;
  color: #000;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  font-size: 20px;
  font-family: 'AngryMonkeySimple';
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.05);
    background: #ffed4a;
  }
`;

const LivesDisplay = styled.div`
  display: flex;
  gap: 10px;
  margin: 10px 0;
  font-size: 32px;  // Make hearts bigger
`;

const Heart = styled.div`
  color: ${props => props.active ? '#ff0000' : '#444'};
  opacity: ${props => props.active ? 1 : 0.3};  // Make inactive hearts more visible
  transition: all 0.3s ease;  // Smooth transition when hearts change
`;

const MuteButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.2s;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
`;

const CharacterSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: white;
  z-index: 10;
  width: 80%; // Make it wider
  max-width: 800px; // Maximum width
  height: ${props => props.height}px;
`;

const CharacterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); // Show 4 characters per row
  gap: 20px;
  padding: 20px;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
`;

const CharacterOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 15px;
  border-radius: 5px;
  transition: transform 0.2s;
  background: ${props => props.selected ? 'rgba(255, 255, 255, 0.2)' : 'transparent'};

  &:hover {
    transform: scale(1.1);
  }

  img {
    width: 64px;
    height: 64px;
  }

  h3 {
    margin: 0;
    font-size: 16px;
    color: white;
  }
`;

const InfoIcon = styled.button`
  background: none;
  border: none;
  color: #FFD700;
  font-size: 16px;
  cursor: pointer;
  padding: 0 5px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

const InfoModal = styled.div`
  position: relative;
  bottom: 420px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.95);
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #FFD700;
  color: white;
  font-family: 'AngryMonkeySimple';
  z-index: 1000;
  width: 700px;
  text-align: left;
`;

const InfoContent = styled.div`
  h3 {
    color: #FFD700;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .requirements-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  
  .requirement {
    margin: 0;
    padding: 10px;
    border-radius: 5px;
    height: 100%;
  }
  
  .unlocked {
    background: rgba(0, 255, 0, 0.1);
    border-left: 3px solid #00ff00;
  }
  
  .locked {
    background: rgba(255, 0, 0, 0.1);
    border-left: 3px solid #ff0000;
  }

  ul {
    margin: 5px 0;
    padding-left: 20px;
  }

  li {
    font-size: 14px;
  }
`;

const CloseButton = styled(Button)`
  margin-top: 15px;
  padding: 5px 15px;
  font-size: 16px;
`;

const ApeEscape = ({ ownedTokens }) => {
  const canvasRef = useRef(null);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(() => {
    // Initialize high score from localStorage
    const savedHighScore = localStorage.getItem('apeEscapeHighScore');
    return savedHighScore ? parseInt(savedHighScore) : 0;
  });
  const extraLives = Math.floor(ownedTokens.length / 10);
  
  // Initialize lives with the correct amount including NFT bonus
  const [lives, setLives] = useState(3 + extraLives);
  const [gameOver, setGameOver] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [gameSpeed, setGameSpeed] = useState(1);
  const [gameStarted, setGameStarted] = useState(false);
  const [floatingTexts, setFloatingTexts] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [showCharacterSelect, setShowCharacterSelect] = useState(true);
  const [tokenImages, setTokenImages] = useState({});
  const [isRestarting, setIsRestarting] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  
  const CANVAS_WIDTH = 1000;
  const CANVAS_HEIGHT = 500;

  // Use ref to track power-up state to ensure it's always current
  const powerUpRef = useRef(null);
  const musicRef = useRef(new Audio(backgroundMusic));

  const hasNFT = ownedTokens.length > 0;
  const specialPowerups = hasNFT ? {
    doubleJump: true,
    scoreMultiplier: 2,
    extraLives: extraLives,
    powerUpDuration: 8000, // 8 seconds for NFT holders
    dashAbility: ownedTokens.length >= 20, // Dash ability for 20+ tokens
    slowMotion: ownedTokens.length >= 30,  // Slow motion for 30+ tokens
    magneticBananas: ownedTokens.length >= 40, // Attract bananas for 40+ tokens
  } : {
    doubleJump: false,
    scoreMultiplier: 1,
    extraLives: 0,
    powerUpDuration: 5000, // 5 seconds for non-holders
    dashAbility: false,
    slowMotion: false,
    magneticBananas: false,
  };

  // Load images
  const loadImage = (src) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = src;
    return img;
  };

  // Initialize sounds
  const sounds = {
    jump: new Audio(jumpSound),
    collect: new Audio(collectSound),
    crash: new Audio(crashSound),
    invisible: new Audio(invisibleSound)
  };

  // Configure sounds
  Object.values(sounds).forEach(sound => {
    sound.volume = 0.3; // Set volume to 30%
  });

  const characters = {
    player1: {
      image: player1,
      name: "Clayton",
      description: ""
    },
    player2: {
      image: player2,
      name: "Dylan",
      description: ""
    },
    player3: {
      image: player3,
      name: "Perry",
      description: ""
    }
  };

  // Add function to fetch token images
  const fetchTokenImage = async (tokenId) => {
    try {
      const response = await fetch(`https://hyapes-apechain.vercel.app/api/${tokenId}`);
      const data = await response.json();
      if (!data.error) {
        // Create a new image to test loading
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
          setTokenImages(prev => ({
            ...prev,
            [tokenId]: data.image
          }));
        };
        img.src = data.image;
      }
    } catch (error) {
      console.error("Error fetching token image:", error);
    }
  };

  // Fetch images for owned tokens
  useEffect(() => {
    if (ownedTokens.length > 0) {
      ownedTokens.forEach(tokenId => {
        fetchTokenImage(tokenId);
      });
    }
  }, [ownedTokens]);

  // Add this utility function at the top level
  const removeBackground = (imageElement) => {
    // Create a canvas to analyze and modify the image
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = imageElement.width;
    canvas.height = imageElement.height;
    
    // Draw the original image
    ctx.drawImage(imageElement, 0, 0);
    
    // Get image data
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    
    // Create color frequency map
    const colorMap = new Map();
    
    // Sample pixels to find most common color (background)
    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      const rgb = `${r},${g},${b}`;
      
      colorMap.set(rgb, (colorMap.get(rgb) || 0) + 1);
    }
    
    // Find the most frequent color
    let maxCount = 0;
    let backgroundColor = '';
    
    colorMap.forEach((count, color) => {
      if (count > maxCount) {
        maxCount = count;
        backgroundColor = color;
      }
    });
    
    // Convert background color string back to RGB values
    const [bgR, bgG, bgB] = backgroundColor.split(',').map(Number);
    
    // Remove background color (with much lower tolerance)
    const tolerance = 10; // Reduced from 30 to 10
    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      
      // Check if pixel is similar to background color
      if (Math.abs(r - bgR) < tolerance &&
          Math.abs(g - bgG) < tolerance &&
          Math.abs(b - bgB) < tolerance) {
        // Make pixel transparent
        data[i + 3] = 0;
      }
    }
    
    // Put the modified image data back
    ctx.putImageData(imageData, 0, 0);
    
    // Create new image with transparent background
    const newImage = new Image();
    newImage.src = canvas.toDataURL('image/png');
    return newImage;
  };

  class Player {
    constructor() {
      this.width = 64;
      this.height = 64;
      this.x = 50;
      this.y = CANVAS_HEIGHT - this.height - 10;
      this.dy = 0;
      this.jumpForce = -15;
      this.baseSpeed = 5;
      this.gravity = 0.8;
      this.isJumping = false;
      this.jumpCount = 0;
      this.maxJumps = specialPowerups.doubleJump ? 2 : 1;
      this.dashSpeed = 0;
      this.isDashing = false;
      this.dashCooldown = false;
      this.dashDirection = 1;
      this.trailEffect = ownedTokens.length >= 15;
      this.trailHistory = [];
      this.trailMaxLength = 8;
      this.trailColors = ['#FFD700', '#FFA500', '#FF4500'];
      
      // Magnetic banana properties
      this.magneticRange = specialPowerups.magneticBananas ? 150 : 0;
      this.magneticForce = 5;
      
      // Handle token-based characters with background removal
      if (selectedCharacter.startsWith('token-')) {
        const tokenId = selectedCharacter.split('-')[1];
        const baseImage = new Image();
        baseImage.crossOrigin = "anonymous";
        
        if (tokenImages[tokenId]) {
          baseImage.src = tokenImages[tokenId];
          baseImage.onload = () => {
            this.image = removeBackground(baseImage);
          };
          this.image = baseImage;
        } else {
          // Fallback to default image if token image not found
          baseImage.src = player1;
          this.image = baseImage;
        }
      } else {
        const baseImage = new Image();
        baseImage.crossOrigin = "anonymous";
        baseImage.src = characters[selectedCharacter].image;
        baseImage.onload = () => {
          this.image = removeBackground(baseImage);
        };
        this.image = baseImage;
      }
      
      this.isInvulnerable = false;
      this.invulnerabilityTime = specialPowerups.powerUpDuration;
      this.isFlashing = false;
      this.flashInterval = null;
      this.invulnerabilityTimeout = null;
      this.rotation = 0;
      this.isFlipping = false;
      this.flipSpeed = 20; // Degrees per frame
    }

    makeInvulnerable(fromCrash = false) {
      if (!fromCrash) {
        // Clear any existing timeouts and intervals
        if (this.invulnerabilityTimeout) {
          clearTimeout(this.invulnerabilityTimeout);
        }
        if (this.flashInterval) {
          clearInterval(this.flashInterval);
        }

        // Power-up animation and sound
        this.isInvulnerable = true;
        this.isFlashing = true;
        sounds.invisible.currentTime = 0;
        sounds.invisible.play().catch(err => console.log('Sound play failed:', err));
        
        // Start new blinking effect
        let isVisible = true;
        this.flashInterval = setInterval(() => {
          isVisible = !isVisible;
          this.isFlashing = isVisible;
        }, 100);

        // Set new timeout for invulnerability end
        this.invulnerabilityTimeout = setTimeout(() => {
          clearInterval(this.flashInterval);
          this.isFlashing = false;
          this.isInvulnerable = false;
        }, this.invulnerabilityTime);
      } else {
        // Play crash sound and start flip
        sounds.crash.currentTime = 0;
        sounds.crash.play().catch(err => console.log('Sound play failed:', err));
        this.isFlipping = true;
        this.rotation = 0;
      }
    }

    update() {
      // Vertical movement
      this.y += this.dy;
      this.dy += this.gravity;

      // Ground collision
      if (this.y > CANVAS_HEIGHT - this.height - 10) {
        this.y = CANVAS_HEIGHT - this.height - 10;
        this.dy = 0;
        this.isJumping = false;
        this.jumpCount = 0;  // Reset jump count when hitting the ground
      }

      // Update flip rotation
      if (this.isFlipping) {
        this.rotation += this.flipSpeed;
        if (this.rotation >= 360) {
          this.rotation = 0;
          this.isFlipping = false;
        }
      }

      // Apply dash movement
      if (this.isDashing) {
        this.x += this.dashSpeed * this.dashDirection;
        // Keep player in bounds
        this.x = Math.max(0, Math.min(this.x, CANVAS_WIDTH - this.width));
      }
    }

    draw(ctx) {
      // Draw trail effect first (behind player)
      if (this.trailEffect) {
        // Store current position in trail history
        this.trailHistory.unshift({
          x: this.x,
          y: this.y,
          rotation: this.rotation,
          isDashing: this.isDashing,
          dashDirection: this.dashDirection
        });
        
        // Limit trail length
        if (this.trailHistory.length > this.trailMaxLength) {
          this.trailHistory.pop();
        }
        
        // Draw trail with improved effects
        this.trailHistory.forEach((pos, i) => {
          const progress = i / this.trailMaxLength;
          const alpha = (1 - progress) * 0.4; // Increased base opacity
          const scale = 1 - (progress * 0.3); // Gradually reduce size
          const colorIndex = Math.floor(progress * this.trailColors.length);
          
          ctx.save();
          ctx.globalAlpha = alpha;
          ctx.translate(pos.x + this.width/2, pos.y + this.height/2);
          
          // Add rotation based on dash state
          if (pos.isDashing) {
            const tiltAngle = pos.dashDirection > 0 ? 15 : -15;
            ctx.rotate((tiltAngle * Math.PI) / 180);
          }
          if (this.isFlipping) {
            ctx.rotate(pos.rotation * Math.PI / 180);
          }
          
          // Add glow effect
          ctx.shadowColor = this.trailColors[colorIndex];
          ctx.shadowBlur = 10;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;
          
          ctx.drawImage(
            this.image,
            -this.width/2 * scale,
            -this.height/2 * scale,
            this.width * scale,
            this.height * scale
          );
          ctx.restore();
        });
      }

      // Draw player
      ctx.save();
      ctx.translate(this.x + this.width/2, this.y + this.height/2);
      if (this.isFlipping) {
        ctx.rotate(this.rotation * Math.PI / 180);
      }
      if (this.isFlashing) {
        ctx.globalAlpha = 0.3;
      }
      ctx.drawImage(
        this.image,
        -this.width/2,
        -this.height/2,
        this.width,
        this.height
      );
      ctx.restore();

      // Draw dash effect (modified to be more subtle)
      if (this.isDashing) {
        ctx.save();
        const gradient = ctx.createLinearGradient(
          this.x - 20,
          this.y + this.height / 2,
          this.x + this.width + 20,
          this.y + this.height / 2
        );
        
        if (this.dashDirection > 0) {
          gradient.addColorStop(0, 'rgba(255, 215, 0, 0)');
          gradient.addColorStop(0.5, 'rgba(255, 215, 0, 0.3)');
          gradient.addColorStop(1, 'rgba(255, 215, 0, 0)');
        } else {
          gradient.addColorStop(0, 'rgba(255, 215, 0, 0)');
          gradient.addColorStop(0.5, 'rgba(255, 215, 0, 0.3)');
          gradient.addColorStop(1, 'rgba(255, 215, 0, 0)');
        }
        
        ctx.strokeStyle = gradient;
        ctx.lineWidth = 3;
        ctx.beginPath();
        
        // Draw the line slightly below or above the character instead of through it
        const yOffset = this.height * 0.7; // Adjust this value to position the line
        if (this.dashDirection > 0) {
          ctx.moveTo(this.x - 20, this.y + yOffset);
          ctx.lineTo(this.x + this.width + 20, this.y + yOffset);
        } else {
          ctx.moveTo(this.x + this.width + 20, this.y + yOffset);
          ctx.lineTo(this.x - 20, this.y + yOffset);
        }
        
        ctx.stroke();
        ctx.restore();
      }
    }

    jump() {
      if (this.jumpCount < this.maxJumps) {
        this.dy = this.jumpForce;
        this.isJumping = true;
        this.jumpCount++;
        sounds.jump.currentTime = 0;
        sounds.jump.play().catch(err => console.log('Sound play failed:', err));
      }
    }

    dash(direction) {
      if (specialPowerups.dashAbility && !this.dashCooldown) {
        // Clear any existing dash intervals/timeouts
        if (this.dashInterval) {
          clearInterval(this.dashInterval);
        }
        if (this.dashTimeout) {
          clearTimeout(this.dashTimeout);
        }

        this.isDashing = true;
        this.dashSpeed = 15;
        this.dashDirection = direction;
        this.dashCooldown = true;
        
        // Gradually decrease dash speed
        this.dashInterval = setInterval(() => {
          this.dashSpeed = Math.max(0, this.dashSpeed - 1);
          if (this.dashSpeed === 0) {
            this.isDashing = false;
            clearInterval(this.dashInterval);
            this.dashInterval = null;
          }
        }, 50);

        // Reset cooldown after a shorter time (reduced from 2000ms to 500ms)
        this.dashTimeout = setTimeout(() => {
          this.dashCooldown = false;
          this.dashTimeout = null;
        }, 50);
      }
    }
  }

  class Banana {
    constructor() {
      this.width = 30;
      this.height = 30;
      this.x = CANVAS_WIDTH;
      this.y = Math.random() * (CANVAS_HEIGHT - 100);
      this.baseSpeed = 5;
      this.image = loadImage(bananaImg);
      this.dx = 0;
      this.dy = 0;
      this.magneticSpeed = 0.5;
    }

    update(player, floatingTexts) {
      // Normal movement
      this.x -= this.baseSpeed * gameSpeed;

      // Magnetic effect when player has enough tokens
      if (specialPowerups.magneticBananas) {
        const dx = player.x + player.width/2 - (this.x + this.width/2);
        const dy = player.y + player.height/2 - (this.y + this.height/2);
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < player.magneticRange) {
          // Calculate magnetic force based on distance
          const force = (1 - distance / player.magneticRange) * this.magneticSpeed;
          
          // Update velocity
          this.dx += (dx / distance) * force;
          this.dy += (dy / distance) * force;
          
          // Apply velocity with some damping
          this.x += this.dx;
          this.y += this.dy;
          this.dx *= 0.95;
          this.dy *= 0.95;

          // Optional: Add visual effect for magnetic attraction
          if (Math.random() < 0.2) { // 20% chance each frame
            floatingTexts.push(
              new FloatingText(
                this.x + Math.random() * this.width,
                this.y + Math.random() * this.height,
                '✨',
                '#FFD700'
              )
            );
          }
        }
      }
    }

    draw(ctx, player) {
      // Draw the banana
      ctx.drawImage(
        this.image,
        this.x,
        this.y,
        this.width,
        this.height
      );
      
      // Add magnetic range visualization when close
      if (specialPowerups.magneticBananas) {
        const distance = Math.sqrt(
          Math.pow(player.x + player.width/2 - (this.x + this.width/2), 2) +
          Math.pow(player.y + player.height/2 - (this.y + this.height/2), 2)
        );
        
        if (distance < player.magneticRange) {
          ctx.save();
          ctx.strokeStyle = '#FFD700';
          ctx.globalAlpha = 0.2 * (1 - distance / player.magneticRange);
          ctx.beginPath();
          ctx.moveTo(this.x + this.width/2, this.y + this.height/2);
          ctx.lineTo(player.x + player.width/2, player.y + player.height/2);
          ctx.stroke();
          ctx.restore();
        }
      }
    }
  }

  class Obstacle {
    constructor() {
      this.width = 30;
      this.height = 80;
      this.x = CANVAS_WIDTH;
      this.y = CANVAS_HEIGHT - this.height - 10;
      this.baseSpeed = 6;  // Store base speed
      this.image = loadImage(obstacleImg);
    }

    update() {
      this.x -= this.baseSpeed * gameSpeed;
    }

    draw(ctx) {
      ctx.drawImage(
        this.image,
        this.x,
        this.y,
        this.width,
        this.height
      );
    }
  }

  class PowerUp {
    constructor() {
      this.width = 30;
      this.height = 30;
      this.x = CANVAS_WIDTH;
      this.y = Math.random() * (CANVAS_HEIGHT - 100);
      this.baseSpeed = 4;
      this.type = Math.random() < 0.5 ? 'invincible' : 'doublePoints';
      this.image = loadImage(this.type === 'invincible' ? starImg : multiplierImg);
    }

    update() {
      this.x -= this.baseSpeed * gameSpeed;
    }

    draw(ctx) {
      ctx.drawImage(this.image, this.x, this.y, this.width, this.height);
    }
  }

  class FloatingText {
    constructor(x, y, text, color = '#FFD700') {
      this.x = x;
      this.y = y;
      this.text = text;
      this.color = color;
      this.lifespan = 1000; // 1 second
      this.startTime = Date.now();
    }

    update() {
      const progress = (Date.now() - this.startTime) / this.lifespan;
      this.y -= 1; // Float upward
      return progress < 1;
    }

    draw(ctx) {
      const progress = (Date.now() - this.startTime) / this.lifespan;
      ctx.fillStyle = this.color;
      ctx.globalAlpha = 1 - progress;
      ctx.font = '20px AngryMonkeySimple';
      ctx.fillText(this.text, this.x, this.y);
      ctx.globalAlpha = 1;
    }
  }

  // Update restartGame to properly reset all necessary states
  const restartGame = () => {
    if (isRestarting) return; // Prevent multiple restarts
    
    setIsRestarting(true);
    setScore(0);
    setLives(3 + extraLives);
    setGameOver(false);
    setGameSpeed(1);
    setGameStarted(true); // Changed from false to true
    powerUpRef.current = null;
    
    if (window.doublePointsTimeout) {
      clearTimeout(window.doublePointsTimeout);
    }

    // Start music again
    if (!isMuted) {
      musicRef.current.currentTime = 0;
      musicRef.current.play().catch(err => console.log('Music play failed:', err));
    }

    // Clear restart flag after a short delay
    setTimeout(() => {
      setIsRestarting(false);
    }, 500);
  };

  const handleGameOver = () => {
    if (score > highScore) {
      setHighScore(score);
      localStorage.setItem('apeEscapeHighScore', score.toString());
    }
    setGameOver(true);
  };

  const handleChangeCharacter = () => {
    setShowCharacterSelect(true);
    setGameOver(false);
    restartGame();
  };

  // Add useEffect to update lives if ownedTokens changes
  useEffect(() => {
    const newExtraLives = Math.floor(ownedTokens.length / 10);
    setLives(3 + newExtraLives);
  }, [ownedTokens]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return; // Add early return if canvas isn't ready

    const ctx = canvas.getContext('2d');
    const background = loadImage(backgroundImg);

    // Only initialize game after character is selected
    if (!selectedCharacter || showCharacterSelect) {
      const drawCharacterSelect = () => {
        ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
        ctx.drawImage(background, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
        
        ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
        ctx.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
        
        ctx.fillStyle = 'white';
        ctx.font = '40px AngryMonkeySimple';
        ctx.textAlign = 'center';
        // ctx.fillText('SELECT YOUR CHARACTER', CANVAS_WIDTH/2, CANVAS_HEIGHT/2 - 100);
      };

      drawCharacterSelect();
      return;
    }

    // Initialize game only after character selection
    const player = new Player();
    let bananas = [];
    let obstacles = [];
    let powerUps = [];
    let activeFloatingTexts = [];
    let lastTime = 0;
    let animationFrameId;
    
    // Load saved high score
    const savedHighScore = localStorage.getItem('apeEscapeHighScore');
    if (savedHighScore) setHighScore(parseInt(savedHighScore));

    // Configure background music
    musicRef.current.loop = true;
    musicRef.current.volume = 0.3;

    const handleKeyDown = (e) => {
      if (e.code === 'Space') {
        e.preventDefault();
        if (!gameStarted) {
          if (!isRestarting) {
            setGameStarted(true);
            musicRef.current.play().catch(err => console.log('Music play failed:', err));
          }
        } else if (gameOver) {
          restartGame();
        } else {
          player.jump();
        }
      } else if (e.code === 'KeyP' && gameStarted && !gameOver) {
        e.preventDefault();
        setIsPaused(!isPaused);
      } else if (e.code === 'KeyM') {
        e.preventDefault();
        setIsMuted(prev => {
          const newMuted = !prev;
          musicRef.current.muted = newMuted;
          return newMuted;
        });
      } else if ((e.code === 'KeyD' || e.code === 'ArrowRight') && specialPowerups.dashAbility) {
        player.dash(1);
      } else if ((e.code === 'KeyA' || e.code === 'ArrowLeft') && specialPowerups.dashAbility) {
        player.dash(-1);
      } else if (e.code === 'KeyS' && specialPowerups.slowMotion) {
        setGameSpeed(prev => prev * 0.5);
        setTimeout(() => setGameSpeed(prev => prev * 2), 3000);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    const addPoints = (basePoints, x, y) => {
      const multiplier = powerUpRef.current === 'doublePoints' ? 2 : 1;
      const nftMultiplier = specialPowerups.scoreMultiplier;
      const points = basePoints * multiplier * nftMultiplier;
      
      setScore(prevScore => prevScore + points);
      
      activeFloatingTexts.push(
        new FloatingText(
          x,
          y,
          `+${points}`,
          powerUpRef.current === 'doublePoints' ? '#ffff00' : '#ffffff'
        )
      );
    };

    // Game loop with timestamp for smooth animations
    const gameLoop = (timestamp) => {
      if (isPaused || gameOver) {
        // Draw paused state
        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
        ctx.fillStyle = 'white';
        ctx.font = '40px AngryMonkeySimple';
        ctx.textAlign = 'center';
        ctx.fillText(isPaused ? 'PAUSED' : '', CANVAS_WIDTH/2, CANVAS_HEIGHT/2);
        animationFrameId = requestAnimationFrame(gameLoop);
        return;
      }

      const deltaTime = timestamp - lastTime;
      lastTime = timestamp;

      // Clear canvas
      ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

      // Draw background
      ctx.drawImage(background, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

      // Update and draw player
      player.update();
      
      // Draw player with invulnerability effect
      if (player.isInvulnerable) {
        ctx.globalAlpha = 0.5;
        player.draw(ctx);
        ctx.globalAlpha = 1.0;
      } else {
        player.draw(ctx);
      }

      // Spawn bananas
      const difficultyMultiplier = Math.max(1, 1 + (ownedTokens.length * 0.05)); // 5% harder per token
      if (Math.random() < 0.02 * difficultyMultiplier) {
        bananas.push(new Banana());
      }

      // Spawn obstacles
      if (Math.random() < 0.01 * difficultyMultiplier) {
        obstacles.push(new Obstacle());
      }

      // Spawn power-ups
      if (Math.random() < 0.005) { // 0.5% chance each frame
        powerUps.push(new PowerUp());
      }

      // Update and draw power-ups
      powerUps = powerUps.filter(powerUp => {
        powerUp.update();
        powerUp.draw(ctx);

        if (player.x < powerUp.x + powerUp.width &&
            player.x + player.width > powerUp.x &&
            player.y < powerUp.y + powerUp.height &&
            player.y + player.height > powerUp.y
        ) {
          if (powerUp.type === 'doublePoints') {
            // Clear any existing timeout
            if (window.doublePointsTimeout) {
              clearTimeout(window.doublePointsTimeout);
            }
            
            powerUpRef.current = 'doublePoints';
            activeFloatingTexts.push(new FloatingText(player.x, player.y, '2x Points', '#ffff00'));
            
            // Set new timeout
            window.doublePointsTimeout = setTimeout(() => {
              powerUpRef.current = null;
              activeFloatingTexts.push(new FloatingText(player.x, player.y, '2x Points Ended', '#ffff00'));
            }, 5000);
          } else if (powerUp.type === 'invincible') {
            player.makeInvulnerable();
            activeFloatingTexts.push(new FloatingText(player.x, player.y, 'Invincible', '#00ff00'));
          }
          return false;
        }
        return powerUp.x > -powerUp.width;
      });

      // Update and draw bananas
      bananas = bananas.filter(banana => {
        banana.update(player, activeFloatingTexts);
        banana.draw(ctx, player);
        
        if (player.x < banana.x + banana.width &&
            player.x + player.width > banana.x &&
            player.y < banana.y + banana.height &&
            player.y + player.height > banana.y
        ) {
          // Use powerUpRef for point calculation
          const basePoints = 1;
          const multiplier = powerUpRef.current === 'doublePoints' ? 2 : 1;
          const nftMultiplier = specialPowerups.scoreMultiplier;
          const points = basePoints * multiplier * nftMultiplier;

          setScore(prevScore => {
            console.log('Adding points:', points); // Debug log
            return prevScore + points;
          });
          
          activeFloatingTexts.push(
            new FloatingText(
              banana.x,
              banana.y,
              `+${points}`,
              powerUpRef.current === 'doublePoints' ? '#ffff00' : '#ffffff'
            )
          );
          
          sounds.collect.currentTime = 0;
          sounds.collect.play().catch(err => console.log('Sound play failed:', err));
          return false;
        }
        return banana.x > -banana.width;
      });

      // Update and draw obstacles
      obstacles = obstacles.filter(obstacle => {
        obstacle.update();
        obstacle.draw(ctx);

        if (player.x < obstacle.x + obstacle.width &&
            player.x + player.width > obstacle.x &&
            player.y < obstacle.y + obstacle.height &&
            player.y + player.height > obstacle.y &&
            !player.isInvulnerable  // Only check invulnerability from power-ups
        ) {
          player.makeInvulnerable(true); // Just for sound
          
          setLives(prevLives => {
            const newLives = prevLives - 1;
            if (newLives <= 0) {
              if (score > highScore) {
                setHighScore(score);
                localStorage.setItem('apeEscapeHighScore', score.toString());
              }
              setGameOver(true);
              return 0;
            }
            return newLives;
          });
          
          return false;
        }
        return obstacle.x > -obstacle.width;
      });

      // Update and draw floating texts
      activeFloatingTexts = activeFloatingTexts.filter(text => {
        if (text.update()) {
          text.draw(ctx);
          return true;
        }
        return false;
      });

      // Increase game speed with score
      if (score > 0 && score % 10 === 0) {
        setGameSpeed(prev => Math.min(prev + 0.1, 2.5));
      }

      animationFrameId = requestAnimationFrame(gameLoop);
    };

    // Start the game loop
    gameLoop(0);

    // Update music state when game is paused
    if (isPaused || gameOver) {
      musicRef.current.pause();
    } else if (gameStarted) {
      musicRef.current.play().catch(err => console.log('Music play failed:', err));
    }

    return () => {
      if (window.doublePointsTimeout) {
        clearTimeout(window.doublePointsTimeout);
      }
      powerUpRef.current = null;
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      musicRef.current.pause();
      musicRef.current.currentTime = 0;
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isPaused, gameOver, gameStarted, selectedCharacter, showCharacterSelect]);

  const toggleMute = () => {
    setIsMuted(prev => {
      const newMuted = !prev;
      musicRef.current.muted = newMuted;
      return newMuted;
    });
  };

  const handleCharacterSelect = (character) => {
    setSelectedCharacter(character);
    setShowCharacterSelect(false);
    setGameStarted(true);
    musicRef.current.play().catch(err => console.log('Music play failed:', err));
  };

  // Add useEffect to watch for score changes and update high score
  useEffect(() => {
    if (score > highScore) {
      setHighScore(score);
      localStorage.setItem('apeEscapeHighScore', score.toString());
    }
  }, [score, highScore]);

  return (
    <>
      <h1>HyApe Escape</h1>
      <GameWrapper>
        <GameCanvas 
          ref={canvasRef} 
          width={CANVAS_WIDTH} 
          height={CANVAS_HEIGHT}
        />
        {showCharacterSelect && (
          <CharacterSelect
            width={CANVAS_WIDTH}
            height={CANVAS_HEIGHT}
          >
            <h2>Choose Your HyApe</h2>
            <CharacterGrid>
              {/* Default characters */}
              {Object.entries(characters).map(([key, char]) => (
                <CharacterOption 
                  key={key} 
                  onClick={() => handleCharacterSelect(key)}
                  selected={selectedCharacter === key}
                >
                  <img src={char.image} alt={char.name} />
                  <h3>{char.name}</h3>
                </CharacterOption>
              ))}
              
              {/* Owned token characters */}
              {ownedTokens.map(tokenId => (
                <CharacterOption 
                  key={`token-${tokenId}`}
                  onClick={() => handleCharacterSelect(`token-${tokenId}`)}
                  selected={selectedCharacter === `token-${tokenId}`}
                >
                  {tokenImages[tokenId] ? (
                    <img src={tokenImages[tokenId]} alt={`Token #${tokenId}`} />
                  ) : (
                    <div>Loading...</div>
                  )}
                  <h3>Token #{tokenId}</h3>
                </CharacterOption>
              ))}
            </CharacterGrid>
            <p>Click to select</p>
          </CharacterSelect>
        )}
        {!showCharacterSelect && (
          <>
            <div style={{ 
              position: 'absolute', 
              top: '10px', 
              left: '10px', 
              background: 'rgba(0, 0, 0, 0.7)',
              padding: '10px',
              borderRadius: '5px',
              color: '#fff',
              fontFamily: 'AngryMonkeySimple',
              textAlign: 'left'
            }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                <ScoreText style={{ fontSize: '16px', margin: '0' }}>
                  🎮 NFT Status:
                </ScoreText>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <InfoIcon onClick={() => setShowInfo(true)}>ℹ️</InfoIcon>
                  <MuteButton onClick={toggleMute} style={{ position: 'static', background: 'none' }}>
                    {isMuted ? '🔇' : '🔊'}
                  </MuteButton>
                </div>
              </div>
              <ScoreText style={{ fontSize: '14px', margin: '5px 0', color: hasNFT ? '#00ff00' : '#ff0000' }}>
                {hasNFT ? '✓ NFT Holder' : '✗ No NFTs Owned'}
              </ScoreText>
              <ScoreText style={{ fontSize: '14px', margin: '5px 0' }}>
                🎯 Tokens Owned: {ownedTokens.length}
              </ScoreText>
              {hasNFT && (
                <>
                  <ScoreText style={{ fontSize: '14px', margin: '5px 0', color: '#00ff00' }}>
                    Active Bonuses:
                  </ScoreText>
                  <ScoreText style={{ fontSize: '12px', margin: '2px 0', color: '#00ff00' }}>
                    • Double Jump Enabled
                  </ScoreText>
                  <ScoreText style={{ fontSize: '12px', margin: '2px 0', color: '#00ff00' }}>
                    • {specialPowerups.scoreMultiplier}x Score Multiplier
                  </ScoreText>
                  <ScoreText style={{ fontSize: '12px', margin: '2px 0', color: '#00ff00' }}>
                    • +{specialPowerups.extraLives} Extra {specialPowerups.extraLives === 1 ? 'Life' : 'Lives'}
                  </ScoreText>
                  {ownedTokens.length >= 15 && (
                    <ScoreText style={{ fontSize: '12px', margin: '2px 0', color: '#00ff00' }}>
                      • Trail Effect Enabled
                    </ScoreText>
                  )}
                  {ownedTokens.length >= 20 && (
                    <ScoreText style={{ fontSize: '12px', margin: '2px 0', color: '#00ff00' }}>
                      • Dash Ability (A/D or ←/→ keys)
                    </ScoreText>
                  )}
                  {ownedTokens.length >= 30 && (
                    <ScoreText style={{ fontSize: '12px', margin: '2px 0', color: '#00ff00' }}>
                      • Slow Motion (S key)
                    </ScoreText>
                  )}
                  {ownedTokens.length >= 40 && (
                    <ScoreText style={{ fontSize: '12px', margin: '2px 0', color: '#00ff00' }}>
                      • Magnetic Bananas
                    </ScoreText>
                  )}
                  {ownedTokens.length < 40 && (
                    <ScoreText style={{ fontSize: '12px', margin: '2px 0', color: '#ffff00' }}>
                      {40 - ownedTokens.length} more tokens for Magnetic Bananas
                    </ScoreText>
                  )}
                </>
              )}
            </div>
            <LivesDisplay>
              {[...Array(3 + specialPowerups.extraLives)].map((_, i) => (
                <Heart key={i} active={i < lives}>
                  ❤️
                </Heart>
              ))}
            </LivesDisplay>
            <ScoreText>Score: {score}</ScoreText>
            <ScoreText>High Score: {highScore}</ScoreText>
            <ScoreText>
              {!gameStarted ? (
                'Press SPACE to Start'
              ) : (
                <span>
                  SPACE to jump | P to pause | M to mute music
                  {specialPowerups.dashAbility && ' | A/D or ←/→ to dash'}
                  {specialPowerups.slowMotion && ' | S for slow-mo'}
                </span>
              )}
            </ScoreText>
          </>
        )}
        {gameOver && !showCharacterSelect && (
          <GameOverScreen>
            <h2>GAME OVER</h2>
            <p>Score: {score}</p>
            <p>High Score: {highScore}</p>
            <div style={{ marginTop: '20px' }}>
              <Button onClick={restartGame} disabled={isRestarting}>
                Play Again
              </Button>
              <Button onClick={handleChangeCharacter} disabled={isRestarting}>
                Change Character
              </Button>
            </div>
          </GameOverScreen>
        )}
      </GameWrapper>
      {showInfo && (
        <InfoModal>
          <InfoContent>
            <h3>🏆 Token Rewards & Abilities</h3>
            
            <div className="requirements-grid">
              <div className={`requirement ${ownedTokens.length >= 1 ? 'unlocked' : 'locked'}`}>
                <strong>Own 1+ Tokens:</strong>
                <ul>
                  <li>Double Jump Ability</li>
                  <li>2x Score Multiplier</li>
                  <li>Longer Power-up Duration (8s vs 5s)</li>
                </ul>
              </div>

              <div className={`requirement ${ownedTokens.length >= 10 ? 'unlocked' : 'locked'}`}>
                <strong>Every 10 Tokens:</strong>
                <ul>
                  <li>+1 Extra Life</li>
                  <li>Current: +{Math.floor(ownedTokens.length / 10)} Lives</li>
                </ul>
              </div>

              <div className={`requirement ${ownedTokens.length >= 15 ? 'unlocked' : 'locked'}`}>
                <strong>15+ Tokens:</strong>
                <ul>
                  <li>Trail Effect</li>
                  <li>Visual flair for your character</li>
                </ul>
              </div>

              <div className={`requirement ${ownedTokens.length >= 20 ? 'unlocked' : 'locked'}`}>
                <strong>20+ Tokens:</strong>
                <ul>
                  <li>Dash Ability (A/D keys)</li>
                  <li>Quick burst of speed in either direction</li>
                </ul>
              </div>

              <div className={`requirement ${ownedTokens.length >= 30 ? 'unlocked' : 'locked'}`}>
                <strong>30+ Tokens:</strong>
                <ul>
                  <li>Slow Motion (S key)</li>
                  <li>Temporarily slow down time</li>
                </ul>
              </div>

              <div className={`requirement ${ownedTokens.length >= 40 ? 'unlocked' : 'locked'}`}>
                <strong>40+ Tokens:</strong>
                <ul>
                  <li>Magnetic Bananas</li>
                  <li>Automatically attract nearby bananas</li>
                </ul>
              </div>
            </div>

            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              <CloseButton onClick={() => setShowInfo(false)}>
                Got it
              </CloseButton>
            </div>
          </InfoContent>
        </InfoModal>
      )}
    </>
  );
};

export default ApeEscape; 