import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import Sidebar from "./Sidebar";
import WalletConnect from "../WalletConnect/WalletConnect"
import twitter from "../../images/white4.png";
import telegram from "../../images/white3.png";
import discord from "../../images/white2.png";
import logo from "../../images/logo.png";

const Wrapper = styled.div`
  padding: 3rem 25px 2rem;

  .hamburger {
    display: none;
    cursor: pointer;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  .wallet_button {
    color: #000;
    background-color: #fff;
    padding: 1rem;
    font-size: 1.8rem;
    font-family: "AngryMonkeySimple";
    margin-left: 2rem;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
      color: #000;
      background-color: #fff;
      border: 1px solid #fff;
    }
  }

  @media only screen and (max-width: 767px) {
    a {
      color: #fff;
    }

    .hamburger {
      display: block;
      display: inline;
      vertical-align: middle;
    }
  }
`;

const Logo = styled.div`
  font-family: "AngryMonkeySimple", sans-serif;
`

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 1.5rem;
  display: inline-block;
  vertical-align: middle;
  width: auto;
`

const MenuContainer = styled.div`
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  align-items: center;

  .social-container a {
    &:hover {
      border-bottom: none;
    }
  }
  
  a {
    margin-left: 2rem;
    padding-bottom: 0.5rem;

    &:hover {
      border-bottom: 2px solid #3C3C3C;
    }
  }
  
   @media only screen and (max-width: 767px) {
    display: none;
  }
`

class Navbar extends React.Component {
  constructor() {
    super()

    this.state = {
      sidebar: false,
    }
  }

  onCheck = () => {
    if (!this.state.sidebar) {
      document.body.classList.add('menu-open')
      this.setState({ sidebar: true })
    }
    else if (this.state.sidebar) {
      document.body.classList.remove('menu-open')
      this.setState({ sidebar: false })
    };
  }

  componentWillUnmount() {
    document.body.classList.remove('menu-open')
    this.setState({ sidebar: false })
  }

  render() {
    const menuItems = [
      // {
      //   text: "About",
      //   url: "/#about",
      // },
      {
        text: "Mint",
        url: "/#mint",
      },
      // {
      //   text: "Roadmap",
      //   url: "/#roadmap",
      // },
      // {
      //   text: "Team",
      //   url: "/#team",
      // },
      // {
      //   text: "Collection",
      //   url: "/mycollection",
      // },
    ];

    return (
      <Wrapper className="globalWrapper fixed" style={{padding: "1.5rem 25px 0.5rem"}}>
        <div className="grid-middle">
          <Logo className="col-2"><NavLink to="/"><img style={{borderRadius: "50%", maxWidth: "20%"}} src={logo}/></NavLink></Logo>
          <div className="col-10 text-right">
            <MenuContainer className="mobile-white">
              {menuItems.map(menuItem => {
                return <a href={menuItem.url}>{menuItem.text}</a>
              })}
              <a href="https://magiceden.io/collections/apechain/0x2fc91c5e872e080b17249cdf2e9c77e2f7748e62" target="_blank" rel="noreferrer noopener">TRADE</a>
              <WalletConnect />
              <div className="social-container">
                <a href="https://twitter.com/hyapesgg" target="_blank" rel="noreferrer noopener"><Icon src={twitter} alt="#" /></a>
                {/* <a href="https://t.me/hychainapes" target="_blank" rel="noreferrer noopener"><Icon src={telegram} alt="#" /></a> */}
                <a href="https://discord.com/invite/v95DyjW88U" target="_blank" rel="noreferrer noopener"><Icon src={discord} alt="#" /></a>
              </div>
            </MenuContainer>
            <GiHamburgerMenu
              className="hamburger"
              size="30"
              color="#fff"
              onClick={this.onCheck}
            />
          </div>
          {this.state.sidebar && <Sidebar menuItem={menuItems} showSidebar={this.onCheck} />}
        </div>
      </Wrapper>
    );
  }
};

export default Navbar;
