import React from 'react';

const NetworkSwitchModal = ({ isOpen, onConfirm, onCancel, title, message, showCancelButton = true }) => {
    if (!isOpen) return null;

    const styles = {
        modalOverlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        },
        modalContent: {
            background: '#211C21',
            padding: '20px',
            borderRadius: '12px',
            maxWidth: '400px',
            width: '90%',
            textAlign: 'center',
            color: 'white'
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '20px'
        },
        button: {
            padding: '10px 20px',
            borderRadius: '6px',
            border: 'none',
            cursor: 'pointer',
            fontFamily: 'inherit',
            fontSize: 'inherit',
        },
        confirmButton: {
            backgroundColor: '#4CAF50',
            color: 'white'
        },
        cancelButton: {
            backgroundColor: '#f44336',
            color: 'white'
        }
    };

    return (
        <div style={styles.modalOverlay}>
            <div style={styles.modalContent}>
                <h2>{title}</h2>
                <p>{message}</p>
                <div style={styles.buttonContainer}>
                    <button 
                        style={{...styles.button, ...styles.confirmButton}}
                        onClick={onConfirm}
                    >
                        {showCancelButton ? 'Yes, Switch Network' : 'OK'}
                    </button>
                    {showCancelButton && (
                        <button 
                            style={{...styles.button, ...styles.cancelButton}}
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NetworkSwitchModal; 