import styled from "styled-components";

import heroBackground from '../../images/bgg.png'
import heroBackgroundMobile from '../../images/bggm.png'
import banner from '../../images/foot3.png'
import banner2 from '../../images/logo.png'
import soundEffect from '../../images/sound.mp3'

const Wrapper = styled.section`
`

const H2 = styled.h2`
  text-align: center;
  margin: 0 auto 20px;

  span {
    background: linear-gradient(90deg, #ff6b6b, #4ecdc4, #45b649, #ff6b6b);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    /* font-size: 2.5rem; */
    font-weight: bold;
    letter-spacing: 2px;
    animation: gradient 8s ease infinite;
    text-shadow: 0 0 10px rgba(255,255,255,0.2);
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  p {
    margin: 0;
  }
`;

const Background = styled.div`
  background-image: url(${ heroBackground });
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 44%;

  @media all and (max-width: 767px) {
    background-image: url(${ heroBackgroundMobile });
    // background-size: cover;
    // padding-top: initial;
    // height: 100vh;
  }
`

const LogoImage = styled.img`
  margin: 5px auto 10px;
  max-width: 15%;
  border-radius: 50%;
  animation: float 3s ease-in-out infinite;
  /* cursor: pointer; */

  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;

const HeroSection = () => {
  const audio = new Audio(soundEffect);

  const playSound = () => {
    audio.currentTime = 0; // Reset the audio to start
    audio.play();
  };

  return (
    <Wrapper id="hero">
      {/* <img src={banner} style={{margin: "5% auto 50px", maxWidth: "20%"}} /> */}
      <LogoImage 
        src={banner2} 
        onMouseEnter={playSound}
      />
      <H2>
        <p>
          <span>
            MINT ON APECHAIN
          </span>
        </p>
      </H2>
      {/* <Background>
      </Background> */}
    </Wrapper>
  );
};

export default HeroSection;
