import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import WalletButton from "../WalletConnect/WalletConnect"
import twitter from "../../images/white4.png";
import telegram from "../../images/white3.png";
import discord from "../../images/white2.png";

const Wrapper = styled.div`
`;

const SidebarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background: white;
`

const SidebarEl = styled.div`
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100vh;
  
  a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    margin: 8px 0;
    padding: 10px 20px;
  }

  @media all and (max-width: 768px) {
    .wallet_button {
      margin-left: 0 !important;
      margin-top: 10px;
    }
  }
`

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 1.5rem;
  margin-top: 5rem;
  display: inline-block;
  vertical-align: middle;
  width: auto;
`

const CloseBtn = styled.div`
  background-color: #000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 30px 30px 0px;
  cursor: pointer;
`

const Sidebar = (props) => {
  const { menuItem, showSidebar } = props;

  return (
    <Wrapper>
      <SidebarContainer>
        <CloseBtn>
          <IoMdClose color="#fff" size={30} onClick={showSidebar} />
        </CloseBtn>
        <SidebarEl>
          <Link to="/" onClick={showSidebar}>
            <NavLink to="/">Homepage</NavLink>
          </Link>
          {/* <Link to="/mycollection" onClick={showSidebar}> */}
            {/* <NavLink to="/mycollection">My Collection</NavLink> */}
          {/* </Link> */}
          <a href="https://magiceden.io/collections/apechain/0x2fc91c5e872e080b17249cdf2e9c77e2f7748e62" target="_blank" rel="noreferrer noopener">TRADE</a>
          <WalletButton />
          <div className="social-container">
            <a href="https://twitter.com/hyapesgg" target="_blank" rel="noreferrer noopener"><Icon src={twitter} alt="#" /></a>
            {/* <a href="https://t.me/gingerbreadboysbsc" target="_blank" rel="noreferrer noopener"><Icon src={telegram} alt="#" /></a> */}
            <a href="https://discord.com/invite/v95DyjW88U" target="_blank" rel="noreferrer noopener"><Icon src={discord} alt="#" /></a>
          </div>
        </SidebarEl>
      </SidebarContainer>
    </Wrapper>
  );
};

export default Sidebar;
