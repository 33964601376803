import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core'
import { ConnectorId, useWalletModal } from '@pantherswap-libs/uikit'
import { injected, bsc, walletconnect } from '../../connectors'
import Web3 from "web3";
import React from 'react';
import NetworkSwitchModal from '../NetworkSwitchModal/NetworkSwitchModal';

const WalletConnect = () => {
    const { account, activate, deactivate } = useWeb3React()
    const web3 = new Web3(Web3.givenProvider);
    const [showNetworkModal, setShowNetworkModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    
    const handleDeactivate = async (e) => {
        // Prevent default behavior if event exists
        if (e) {
            e.preventDefault();
        }

        try {
            // Disconnect wallet
            await deactivate();
            
            // Clear any local storage items
            localStorage.removeItem('walletconnect');
            localStorage.removeItem('connectorId');  // If you're using this
            localStorage.removeItem('walletconnect-connected');  // If you're using this
            
            // Remove MetaMask listeners
            if (window.ethereum && window.ethereum.removeAllListeners) {
                window.ethereum.removeAllListeners();
            }

            // If using WalletConnect, close its sessions
            if (walletconnect && walletconnect.close) {
                await walletconnect.close();
            }

            // Reset any Web3 instances
            if (web3 && web3.currentProvider && web3.currentProvider.close) {
                await web3.currentProvider.close();
            }

        } catch (error) {
            console.error('Error during disconnection:', error);
        }
    };

    const switchToApeChain = async () => {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x8173' }], // 33139 in hex
            });
            return true;
        } catch (error) {
            // Error code 4902 means the chain hasn't been added yet
            if (error.code === 4902) {
                return await addApeChainNetwork();
            }
            console.error('Error switching to ApeChain:', error);
            return false;
        }
    };

    const addApeChainNetwork = async () => {
        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: '0x8173', // 33139 in hex
                    chainName: 'ApeChain',
                    nativeCurrency: {
                        name: 'APE',
                        symbol: 'APE',
                        decimals: 18
                    },
                    rpcUrls: ['https://rpc.apechain.com/'],
                    blockExplorerUrls: ['https://apescan.io/']
                }]
            });
            return true;
        } catch (error) {
            console.error('Error adding ApeChain network:', error);
            return false;
        }
    };
    
    async function signIn() {
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const chainId = await web3.eth.getChainId();
            
            if (chainId !== 33139) {
                setShowNetworkModal(true);
                return;
            }
            
            const wallet = accounts[0];
            return wallet;
        } catch (error) {
            console.error('Error connecting wallet:', error);
            throw error;
        }
    }

    const handleNetworkConfirm = async () => {
        try {
            setShowNetworkModal(false);
            const success = await switchToApeChain();
            if (!success) {
                setShowErrorModal(true);
                return;
            }
            await handleLogin();
        } catch (error) {
            console.error('Error switching network:', error);
            setShowErrorModal(true);
        }
    };

    const handleNetworkCancel = () => {
        setShowNetworkModal(false);
    };

    const handleErrorClose = () => {
        setShowErrorModal(false);
    };

    const handleLogin = async () => {
        try {
            await signIn();
            await activate(injected, undefined, true);
        } catch (error) {
            console.error('Login failed:', error);
        }
    }

    const { onPresentConnectModal } = useWalletModal(handleLogin, handleDeactivate, account)

    const { onPresentAccountModal } = useWalletModal(handleLogin, handleDeactivate, account)

    // Add cleanup effect
    useEffect(() => {
        const cleanup = () => {
            if (window.ethereum && window.ethereum.removeAllListeners) {
                window.ethereum.removeAllListeners();
            }
        };

        window.addEventListener('beforeunload', cleanup);
        
        return () => {
            cleanup();
            window.removeEventListener('beforeunload', cleanup);
        };
    }, []);

    // Prevent the default form submission if it's within a form
    const handleAccountModalClick = (e) => {
        e.preventDefault();
        onPresentAccountModal();
    };

    const handleConnectModalClick = (e) => {
        e.preventDefault();
        onPresentConnectModal();
    };

    return (
        <>
            {!account ? 
                <button 
                    type="button" 
                    className="wallet_button not-connected" 
                    onClick={handleConnectModalClick}
                >
                    Connect Wallet
                </button>
            : 
                <button 
                    type="button" 
                    className="wallet_button connected" 
                    onClick={handleAccountModalClick}
                >
                    Wallet Connected
                </button>
            }

            <NetworkSwitchModal 
                isOpen={showNetworkModal}
                onConfirm={handleNetworkConfirm}
                onCancel={handleNetworkCancel}
                title="Wrong Network"
                message="Would you like to add / switch to ApeChain?"
            />

            <NetworkSwitchModal 
                isOpen={showErrorModal}
                onConfirm={handleErrorClose}
                title="Network Switch Failed"
                message="Failed to switch to ApeChain. Please try adding the network manually."
                showCancelButton={false}
            />
        </>
    );
}

export default WalletConnect
